import React from 'react';

const PauseIcon = () => (
  <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1">
    <title>Button - Pause - Normal</title>
    <g id="Button---Pause---Normal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <line x1="20" y1="31" x2="20" y2="17" id="Line_18" stroke="#FFFFFF" className="stroke-g" strokeWidth="4"></line>
      <line x1="28" y1="31" x2="28" y2="17" id="Line_19" stroke="#FFFFFF" className="stroke-g" strokeWidth="4"></line>
    </g>
  </svg>
);

export default PauseIcon;
