import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { lazyLoadZendesk } from '../../util/zendesk';
import './styles.scss';

export default function Layout ({ children, title, backgroundStyle, zendesk=true, className }) {
  const [ zendeskState, setZendeskState ] = useState(null);

  useEffect(() => {
    const zE = window.zE;

    if (zendesk) {
      if (!zendeskState) {
        lazyLoadZendesk(() => setZendeskState('loaded'));
      } else if (zendeskState !== 'shown') {
        zE.show();
        zE('webWidget', 'show');
        setZendeskState('shown');
      }
    } else {
      if (!zendeskState && zE && 'function' === typeof zE.activate) {
        zE.hide();
        zE('webWidget', 'hide');
        setZendeskState('hidden');
      } else if (zendeskState === 'shown') {
        zE.hide();
        zE('webWidget', 'hide');
        setZendeskState('hidden');
      }
    }
  }, [ zendesk, zendeskState ]);

  const classes = [
    className || '',
    'layout',
    backgroundStyle ? `bg-${backgroundStyle}` : ''
  ].join(' ');

  return (
    <main className={classes}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      { children }
    </main>
  );
}
