import React from 'react';

export const SaveIcon = () => (
  <svg width="21" height="24" viewBox="0 0 21 24">
    <defs>
      <path id="uymz52ec8a" d="M0 0L19.8 0 19.8 17.4 0 17.4z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-35 -127) translate(21 116) translate(14.4 14.4)">
            <mask id="mgax88i8lb" fill="#fff" className="fill-g">
              <use xlinkHref="#uymz52ec8a" />
            </mask>
            <path fill="#FFF" className="fill-g" d="M10.37 8.757l-.007-.01-.156-.167c-.086-.091-.2-.135-.313-.135-.115 0-.23.044-.316.135l-.155.167c-.005.002-.005.007-.007.01L7.18 11.25c-.173.183-.173.478 0 .661l.155.109c.175.181.454.12.627-.06l1.317-1.485v6.299c0 .345.278.625.62.625.342 0 .62-.28.62-.625v-6.292l1.36 1.516c.172.181.452.242.627.06l.155-.108c.172-.181.172-.478 0-.66L10.37 8.758zm4.393-4.981C13.9 1.463 12.042 0 9.24 0 5.673 0 3.176 2.673 2.96 6.161 1.244 6.717 0 8.49 0 10.495c0 2.455 1.913 4.53 4.176 4.53h1.789c.342 0 .62-.28.62-.626 0-.345-.278-.623-.62-.623h-1.79c-1.592 0-2.955-1.504-2.955-3.281 0-1.572 1.09-2.991 2.46-3.228l.56-.096-.048-.575-.004-.007c0-3.045 1.982-5.34 5.05-5.34 2.462 0 3.842 1.223 4.48 3.31l.134.429.447.01c2.299.048 4.315 1.934 4.315 4.273 0 2.13-1.397 4.505-3.513 4.505h-1.416c-.342 0-.62.278-.62.623s.278.625.62.625l1.41-.002c3.095-.08 4.705-3.066 4.705-5.751 0-2.885-2.236-5.19-5.037-5.495z" mask="url(#mgax88i8lb)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const SaveDiskIcon = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.7 0.475V5.225C5.7 5.48734 5.91266 5.7 6.175 5.7H12.825C13.0873 5.7 13.3 5.48734 13.3 5.225V0.475C13.3 0.212665 13.5127 0 13.775 0H14.413C14.9169 0 15.4002 0.200178 15.7565 0.556497L18.4435 3.2435C18.7998 3.59982 19 4.08309 19 4.58701V17.1C19 18.1493 18.1493 19 17.1 19H1.9C0.850659 19 0 18.1493 0 17.1V1.9C0 0.850659 0.850659 0 1.9 0H5.225C5.48734 0 5.7 0.212665 5.7 0.475ZM9.975 0H10.925C11.1873 0 11.4 0.212665 11.4 0.475V3.325C11.4 3.58734 11.1873 3.8 10.925 3.8H9.975C9.71266 3.8 9.5 3.58734 9.5 3.325V0.475C9.5 0.212665 9.71266 0 9.975 0ZM9.5 15.675C7.66365 15.675 6.175 14.1863 6.175 12.35C6.175 10.5137 7.66365 9.025 9.5 9.025C11.3363 9.025 12.825 10.5137 12.825 12.35C12.825 14.1863 11.3363 15.675 9.5 15.675Z" fill="white"/>
  </svg>
);
