import React from 'react';

const SettingsIcon = () => (
  <svg width="44" height="45" viewBox="0 0 44 45">
    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g className="stroke-g" stroke="#2e3d1a" strokeWidth="2">
        <g>
          <g>
            <g transform="translate(-797 -124) translate(234 116) translate(563.23 8.708)">
              <path d="M36.417 27.708c-.54 1.222-.28 2.648.653 3.602l.119.12c1 1 1.39 2.458 1.025 3.825-.367 1.366-1.434 2.434-2.8 2.8-1.368.366-2.826-.024-3.827-1.025l-.118-.119c-.946-.916-2.346-1.18-3.56-.672-1.215.508-2.01 1.691-2.021 3.008v.336c0 2.186-1.773 3.959-3.959 3.959s-3.958-1.773-3.958-3.959v-.178c-.032-1.34-.88-2.525-2.138-2.988-1.221-.54-2.647-.28-3.602.653l-.118.119c-1.001 1-2.46 1.39-3.826 1.025-1.367-.367-2.434-1.434-2.8-2.8-.367-1.368.024-2.826 1.024-3.827l.12-.118c.915-.946 1.18-2.346.671-3.56-.508-1.215-1.69-2.01-3.007-2.021h-.337C1.772 25.887 0 24.114 0 21.928s1.772-3.958 3.958-3.958h.178c1.341-.032 2.526-.88 2.989-2.138.539-1.221.28-2.647-.653-3.602l-.119-.118c-1-1.001-1.391-2.46-1.025-3.826.366-1.367 1.434-2.434 2.8-2.8 1.367-.367 2.826.024 3.826 1.024l.119.12c.954.933 2.38 1.191 3.602.652h.158c1.197-.512 1.974-1.687 1.98-2.988v-.337C17.813 1.772 19.584 0 21.77 0s3.958 1.772 3.958 3.958v.178c.012 1.317.806 2.5 2.02 3.008 1.215.508 2.616.244 3.561-.672l.12-.119c1-1 2.458-1.391 3.825-1.025 1.366.366 2.434 1.434 2.8 2.8.366 1.367-.024 2.826-1.025 3.826l-.119.119c-.933.954-1.192 2.38-.653 3.602v.158c.513 1.197 1.687 1.974 2.989 1.98h.336c2.186 0 3.959 1.772 3.959 3.958s-1.773 3.958-3.959 3.958h-.178c-1.301.005-2.476.783-2.988 1.98z" />
              <circle cx="21.771" cy="21.771" r="5.938" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SettingsIcon;
