import React from 'react';

const TableDeleteIcon = () => (
  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
    <g id="button---table---delete---hover" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon" stroke="#FFFFFF" transform="translate(9.500000, 8.500000)" className="stroke-g">
        <path d="M1.5,2 L11.5,2 C11.5,8.99157588 11.5,12.8249092 11.5,13.5 C11.5,14.5126362 10.9783821,15 10,15 C9.02161789,15 3.96267891,15 2.99736977,15 C2.03206062,15 1.5,14.5163307 1.5,13.5 C1.5,12.8224462 1.5,8.98911285 1.5,2 Z" id="Line-Copy-2" strokeLinecap="round" strokeLinejoin="round"></path>
        <line x1="0.5" y1="2" x2="12.5" y2="2" id="Line" strokeLinecap="round"></line>
        <line x1="4.5" y1="5" x2="4.5" y2="12" id="Line-Copy" strokeLinecap="round"></line>
        <line x1="6.5" y1="5" x2="6.5" y2="12" id="Line-Copy-3" strokeLinecap="round"></line>
        <line x1="8.5" y1="5" x2="8.5" y2="12" id="Line-Copy-4" strokeLinecap="round"></line>
        <path d="M8.5,2 C8.5,0.8954305 7.6045695,0 6.5,0 C5.3954305,0 4.5,0.8954305 4.5,2" id="Path"></path>
      </g>
    </g>
  </svg>
);

export default TableDeleteIcon;
