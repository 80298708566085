import React from 'react';

const PersonIcon = () => (
  <svg width="58" height="63" viewBox="0 0 58 63">
    <defs>
      <path id="iehh9z89wa" d="M0 0L58 0 58 63 0 63z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-654.000000, -258.000000) translate(608.000000, 214.000000) translate(46.000000, 44.000000)">
            <mask id="anfatx97pb" fill="#fff" className="fill-g">
              <use xlinkHref="#iehh9z89wa" />
            </mask>
            <path fill="#FFF" className="fill-g" d="M53.36 58.336H4.64v-4.168c.333-.244.798-.527 1.164-.696.11-.05.217-.103.322-.162l14.985-8.166c1.365-.746 2.27-2.128 2.409-3.683.138-1.554-.507-3.078-1.719-4.055-3.89-3.135-5.558-10.078-5.558-13.78v-9.848c0-3.348 6.282-9.117 12.863-9.117 6.701 0 12.633 5.69 12.633 9.117v9.847c0 3.652-1.127 10.626-5.131 13.792-1.225.968-1.885 2.492-1.754 4.055.13 1.562 1.033 2.952 2.4 3.702l14.605 8.133c.13.07.294.147.43.206.342.146.761.39 1.07.604v4.22zm.723-9.125l-14.607-8.13c5.428-4.291 6.904-12.728 6.904-17.456v-9.847C46.38 7.262 37.76 0 29.106 0c-8.655 0-17.5 7.262-17.5 13.778v9.847c0 4.3 1.81 13 7.294 17.42L3.914 49.21S0 50.966 0 53.151v5.91C0 61.235 1.756 63 3.914 63h50.17C56.243 63 58 61.235 58 59.06v-5.91c0-2.317-3.917-3.939-3.917-3.939z" mask="url(#anfatx97pb)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default PersonIcon;
