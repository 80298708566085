import React from 'react';

const PoseRestIcon = () => (
  <svg width="50px" height="50px" viewBox="0 0 50 50" version="1.1">
    <title>Button - Rest - Normal</title>
    <g id="Button---Rest---Normal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M39.475,24.254 C37.7365094,23.7358216 35.8998899,23.6374618 34.116,23.967 L34.116,23.967 C34.4798583,21.9942223 34.3230329,19.9606624 33.661,18.067 C31.6938317,18.4378553 29.8581932,19.3177482 28.337,20.619 C27.6684348,18.731566 26.5180714,17.0516975 25,15.746 C23.4813561,17.0528076 22.3309486,18.7341194 21.663,20.623 C20.1412205,19.3232392 18.3063187,18.4435343 16.34,18.071 C15.6780126,19.9646719 15.5211882,21.9982198 15.885,23.971 L15.885,23.971 C13.9174436,23.6066068 11.8888325,23.7624152 10,24.423 C11.0254211,29.7187411 15.5890568,33.5933222 20.981,33.746 C22.3485808,33.7724423 23.7098123,33.553304 25,33.099 C28.1430093,34.2049263 31.6124531,33.881252 34.4966542,32.2130301 C37.3808553,30.5448082 39.3914107,27.6988509 40,24.423 C39.826,24.362 39.651,24.306 39.475,24.254 Z M32.888,19.411 C34.0846687,24.4662312 31.3674445,29.6291706 26.523,31.505 C28.6692079,28.7695643 29.4645263,25.2108449 28.687,21.822 C29.8759097,20.6960034 31.3160135,19.869512 32.888,19.411 Z M17.112,19.411 C18.6836299,19.8696571 20.1233763,20.6961401 21.312,21.822 C20.5344737,25.2108449 21.3297921,28.7695643 23.476,31.505 C18.6319516,29.6287979 15.9152082,24.4659608 17.112,19.411 L17.112,19.411 Z M21.193,32.624 C16.6321016,32.5841894 12.632446,29.5695327 11.338,25.196 C12.9283077,24.8060956 14.5887797,24.8019813 16.181,25.184 C17.1960009,28.5066924 19.6613695,31.1908372 22.886,32.484 C22.3268279,32.5773761 21.7609147,32.6245355 21.194,32.625 L21.193,32.624 Z M24.476,30.955 C21.2171697,26.9085989 21.4407824,21.0788787 25,17.294 C28.7620327,21.3021957 28.7620327,27.5438043 25,31.552 C24.817,31.36 24.644,31.16 24.477,30.956 L24.476,30.955 Z M33.931,31.244 C31.8697279,32.4312644 29.4608882,32.8680146 27.114,32.48 C30.3386305,31.1868372 32.8039991,28.5026924 33.819,25.18 C35.4112203,24.7979813 37.0716923,24.8020956 38.662,25.192 C37.9251088,27.7444969 36.2304332,29.9135384 33.932,31.246 L33.931,31.244 Z" id="Icon_Lotus" fill="#2e3d1a" fillRule="nonzero" className="fill-g"></path>
    </g>
  </svg>
);

export default PoseRestIcon;
