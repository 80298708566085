import React from 'react';

export const PlayIcon = () => (
  <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1">
    <title>Button - Play - Normal</title>
    <g id="Button---Play---Normal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon id="Icon_Play" className="fill-g" fill="#FFFFFF" fillRule="nonzero" points="19 15 33 24 19 33"></polygon>
    </g>
  </svg>
);

export const SequencePlayIcon = ({ color = '#E58061'}) => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path d="M3 1L17 9.5L3 18V1Z" fill={color} />
  </svg>
);
