import React from 'react';

const PlusIcon = () => (
  <svg width="14" height="15" viewBox="0 0 14 15">
    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g stroke="#2e3d1a" className="stroke-g" strokeWidth="2">
        <g>
          <g>
            <path d="M7 0L7 14M0 7L14.002 7" transform="translate(-565 -785) translate(317 755) translate(248 30.5)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default PlusIcon;
