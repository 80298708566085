import React from 'react';

const TableShareIcon = () => (
  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
    <g id="button---table---share---hover" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon" transform="translate(15.000000, 16.000000) scale(-1, 1) translate(-15.000000, -16.000000) translate(7.000000, 8.000000)">
        <polyline className="stroke-g" id="Line-Copy-2" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" points="2 2 14 8 2 14"></polyline>
        <circle id="Oval" fill="#FFFFFF" cx="2" cy="14" r="2"></circle>
        <circle id="Oval-Copy" fill="#FFFFFF" cx="2" cy="2" r="2"></circle>
        <circle id="Oval-Copy-3" fill="#FFFFFF" cx="14" cy="8" r="2"></circle>
      </g>
    </g>
  </svg>
);

export default TableShareIcon;
