import React from 'react';

const SearchIcon = () => (
  <svg width="10px" height="10px" viewBox="0 0 10 10" version="1.1">
    <title>icon - search - normal</title>
    <g id="icon---search---normal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="search" transform="translate(5.000000, 5.000000) scale(-1, 1) translate(-5.000000, -5.000000) " stroke="#2e3d1a" className="stroke-g">
        <circle id="Oval" cx="6" cy="4" r="4"></circle>
        <line x1="3" y1="7" x2="0" y2="10" id="Line-3" strokeLinecap="round"></line>
      </g>
    </g>
  </svg>
);

export default SearchIcon;
