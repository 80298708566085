import React from 'react';

const TableDuplicateIcon = () => (
  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
    <g id="button---table---duplicate---normal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-3" stroke="#FFFFFF" transform="translate(8.000000, 8.000000)" className="stroke-g">
        <line x1="3.5" y1="9" x2="8.5" y2="9" id="Line-6" strokeLinecap="round"></line>
        <rect id="Rectangle" x="0" y="3" width="12" height="12" rx="2"></rect>
        <path d="M4,0 L13,0 C14.1045695,0 15,0.8954305 15,2 L15,11" id="Path" strokeLinecap="round"></path>
        <line x1="6" y1="6.5" x2="6" y2="11.5" id="Line-2" strokeLinecap="round"></line>
      </g>
    </g>
  </svg>
);

export default TableDuplicateIcon;
